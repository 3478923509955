import { formatRFC3339, parseISO } from 'date-fns/fp';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { pipe } from 'ramda';
import React, { useContext } from 'react';

import { DEFAULT_DESCRIPTION, DEFAULT_TITLE } from '~constants';
import { StateContext } from '~providers';
import { config, isDev } from '~services';
import { createLD, getCanonical } from '~utils';

export interface MetaProps {
  authors?: { name: string }[];
  canonical?: string;
  description?: string;
  id?: number;
  image?: string;
  isPublication?: boolean;
  modifiedAt?: string;
  noIndex?: boolean;
  ogDescription?: string;
  ogTitle?: string;
  publishedAt?: string;
  siteName?: string;
  tags?: { name: string }[];
  title?: string;
  type?: string;
}

const formatDate = pipe(parseISO, formatRFC3339);

export const Meta: React.FC<MetaProps> = (props) => {
  const {
    authors,
    canonical,
    description,
    id,
    image,
    isPublication,
    modifiedAt,
    noIndex = false,
    ogDescription,
    ogTitle,
    publishedAt,
    siteName = '',
    tags,
    title,
    type = 'article',
  } = props;

  const router = useRouter();
  const url = getCanonical(router.asPath);

  const {
    state: { settings },
  } = useContext(StateContext);
  const { siteTitle } = settings;

  const metaSiteName = siteTitle || siteName;
  const metaTitle = title || DEFAULT_TITLE;
  const metaOgTitle = ogTitle || DEFAULT_TITLE;
  const metaDescription = description || DEFAULT_DESCRIPTION;
  const shareUrl = image || `${config.ORIGIN_URL}/share.png`;

  const formattedPublishedAt = publishedAt ? formatDate(publishedAt) : '';
  const formattedModifiedAt = modifiedAt ? formatDate(modifiedAt) : '';

  if (type === '404') {
    return (
      <Head>
        <title key="title">{title || DEFAULT_TITLE}</title>
      </Head>
    );
  }

  if (description && ogDescription) {
    return (
      <Head>
        <meta name="description" content={description} key="description" />
        <meta property="og:description" content={ogDescription} key="og:description" />
        <meta name="twitter:description" content={ogDescription} key="twitter:description" />
      </Head>
    );
  }

  return (
    <Head>
      <title key="title">{metaTitle}</title>
      <meta name="description" content={metaDescription} key="description" />
      <meta
        name="viewport"
        content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, shrink-to-fit=no"
      />
      {!isDev && <meta name="yandex-verification" content="08a9de6ed7b59350" />}

      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {canonical ? <link rel="canonical" href={canonical} key="canonical" /> : null}

      {/* Favicon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1e4fbd" />
      <meta name="msapplication-TileColor" content="#141719" />
      <meta name="theme-color" content="#ffffff" />

      {/* OpenGraph */}
      <meta property="og:site_name" content={metaSiteName} key="og:site_name" />
      <meta property="og:title" content={metaOgTitle} key="og:title" />
      <meta property="og:description" content={metaDescription} key="og:description" />
      {canonical ? <meta property="og:url" content={canonical} key="og:url" /> : null}
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:image" content={shareUrl} key="og:image" />
      <meta property="og:image:width" content="1024" />
      <meta property="og:image:height" content="512" />
      {isPublication ? <meta property="og:type" content={type} key="og:type" /> : null}

      <meta name="twitter:title" content={metaOgTitle} key="twitter:title" />
      <meta name="twitter:description" content={metaDescription} key="twitter:description" />
      <meta name="twitter:image:src" content={shareUrl} key="twitter:image:src" />
      <meta name="twitter:url" content={shareUrl} key="twitter:url" />
      {isPublication ? <meta name="twitter:card" content="summary_large_image" /> : null}

      <meta property="vk:image" content={shareUrl} key="vk:image" />
      <meta name="theme-color" content="#151515" />
      <meta name="msapplication-navbutton-color" content="#151515" />
      <meta name="apple-mobile-web-app-status-bar-style" content="#151515" />

      {isPublication && publishedAt ? (
        <meta
          property="article:published_time"
          content={formattedPublishedAt}
          key="article:published_time"
        />
      ) : null}

      <script>window.yaContextCb = window.yaContextCb || []</script>
      <script src="https://yandex.ru/ads/system/context.js" async />

      {config.IS_PRODUCTION && (
        <Script
          id="googleTagManager"
          strategy="afterInteractive"
          src="/scripts/googleTagManager.js"
        />
      )}

      {isPublication ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: createLD({
              authors: authors || [],
              canonical: canonical || '',
              cover: shareUrl,
              dateModified: formattedModifiedAt,
              datePublished: formattedPublishedAt,
              description: metaDescription,
              id: `${id}` || '',
              siteName: metaSiteName,
              tags: tags || [],
              title: metaTitle,
              url,
            }),
          }}
        />
      ) : null}
      {config.ZEN_VERIFICATION ? (
        <meta name="zen-verification" content={config.ZEN_VERIFICATION} />
      ) : null}
    </Head>
  );
};
