import { DEFAULT_DESCRIPTION, ORGANIZATION } from '~constants';
import { config } from '~services/config';

export interface LDProps {
  authors: { name: string }[];
  canonical: string;
  cover: string;
  dateModified: string;
  datePublished: string;
  description: string;
  id: string;
  siteName: string;
  tags: { name: string }[];
  title: string;
  url: string;
}

const defaultUrl = `${config.ORIGIN_URL}`;

export const createLD = ({
  authors,
  canonical,
  cover,
  dateModified,
  datePublished,
  description,
  id,
  siteName,
  tags,
  title,
  url,
}: LDProps) => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  const about =
    tags.length > 0
      ? tags.map((tag) => ({
          '@type': 'Thing',
          name: tag.name,
        }))
      : {};

  const author =
    authors.length > 0
      ? authors.map((authorElem) => ({
          '@type': 'Person',
          name: authorElem.name,
        }))
      : {
          '@id': 'NewsMediaOrganization',
        };

  const mainEntityOfPage = {
    '@id': canonical,
    '@type': 'WebPage',
    description,
    inLanguage: 'ru-RU',
    isPartOf: {
      '@id': defaultUrl,
      '@type': 'WebPage',
      description: DEFAULT_DESCRIPTION,
      inLanguage: 'ru-RU',
      name: siteName,
      url: defaultUrl,
    },
    name: title,
    url: canonical,
  };

  const image = [
    {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      height: 512,
      url: cover,
      width: 1024,
    },
  ];

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'NewsArticle',
    ...(tags.length > 0 ? { about } : {}),
    author,
    copyrightYear: year,
    dateModified,
    datePublished,
    description,
    genre: 'Новости',
    headline: title,
    identifier: id,
    image,
    inLanguage: 'ru-RU',
    isAccessibleForFree: true,
    mainEntityOfPage,
    publisher: ORGANIZATION,
    url,
  });
};

export const getCanonical = (path: string): string => {
  return `${config.ORIGIN_URL}${path.replace(/(\/$|\/amp.*|\/*\?.*)/, '')}`;
};
